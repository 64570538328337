import React from "react";
import cnn from "../../assets/projects/CXQ-ML/cnn.png";
import qnn from "../../assets/projects/CXQ-ML/qnn.png";
import ant from "../../assets/projects/CXQ-ML/ant.png";
import bee from "../../assets/projects/CXQ-ML/bee.png";
import covid from "../../assets/projects/CXQ-ML/covid.png";
import malaria from "../../assets/projects/CXQ-ML/malaria.png";
import mnist from "../../assets/projects/CXQ-ML/mnist.png";

const CXQ = () => {


  return (
    <div className="cxq">
      <div className="content">
        <h2 className="title">CXQ-ML</h2>
          <p className="description"> A comparison of Classical and Quantum Machine Learning Approach 
                                      for Neural Networks in image classification. </p>
                                    
          
          <h2 className="subtitle">Classical convolutional Layer</h2>
          <img src={cnn} alt="cnn" />

          <h2 className="subtitle">Quanvolutional Layer</h2>
          <img src={qnn} alt="qnn" />

          <p className="description"> The Idea is simple: compare a "classical" convolutional 
                                      neural network and a "quantum" convolutional neural network. 
                                      The motivation came from the quanvolutional layer and the tutorial 
                                      by pennylane. The intention was to take one step further, 
                                      and apply the concepts for real world dataset scenarios, 
                                      and try to visualize the effect of the quantum approach. </p>
          

          <h2 className="subtitle">Results</h2>

          <h2 className="subsubtitle">Ants</h2>
          <img src={ant} alt="ant" />

          <h2 className="subsubtitle">Bees</h2>
          <img src={bee} alt="bee" />

          <h2 className="subsubtitle">MNIST</h2>
          <img src={mnist} alt="mnist" />

          <h2 className="subsubtitle">Covid</h2>
          <img src={covid} alt="covid" />

          <h2 className="subsubtitle">Malaria</h2>
          <img src={malaria} alt="malaria" />


          <h2 className="subtitle">Conclusion</h2>

          <p className="description"> Quantum computing can help to produce the same answers that 
                                      classical computing can (see Results section above). 
                                      The big bottleneck currently is the limitation of number of 
                                      qubits available. Classical Deep Learning has been successful 
                                      due to the GPU capacity of heavy and parallel computing. 
                                      Even that Quantum computers can take advantage of parallel 
                                      computing by using superposition, the limitation of number 
                                      of qubits available makes computing really slow for real dataset 
                                      scenarios. For the datasets ants/bee from Imagenet the quantum 
                                      pre-processing took a few hours and for the Malaria dataset,
                                       it took a couple days (reshaping g the original images to 
                                       100x100x1). </p>



      </div>



      <div className="source-container">
        <p className="source">
          You can check the source code for the CXQ-ML project in 
            <a href="https://github.com/dedsylva/CXQ-ML"> here </a>
        </p>
      </div>
    </div>
  )    

}

export default CXQ;