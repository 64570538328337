import React, {useState} from "react";
import MainPage from "./components/MainPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./scss/index.scss";
import About from "./components/About";
import Projects from "./components/Projects";
import Posts from "./components/Posts";
import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {
  const [theme, setTheme] = useState('dark');

  const changeTheme = () => { theme === "dark" ? setTheme("light") : setTheme("dark"); }

  return (
      <div className="app-container" data-theme={theme}>
        <Navbar changeTheme={changeTheme} currentTheme={theme}/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />}/>
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/posts" element={<Posts />} />
          </Routes>
        </BrowserRouter>
        <Footer currentTheme={theme} />
      </div>

 );
}

export default App;
