import React from "react";
import { BsInstagram, BsGithub } from "react-icons/bs";
import dark_logo from "../assets/logo_name_dark_theme.png";
import light_logo from "../assets/logo_name_light_theme.png";

const Footer = ({currentTheme}) => {
  const socialLink = [
    <BsGithub  onClick={() => window.location.href("http://github.com/dedsylva/")}/>, 
    <BsInstagram onClick={() => window.location.href("http://instagram.com/dedsylva/")}/> , 
  ];

  return (
    <footer>
      <div className="upper">
        <div className="brand-container">
          <div className="brand">
            <img src={currentTheme === "dark" ? dark_logo : light_logo} alt="logo" />
          </div>
          <ul>
            {socialLink.map((link, index) => 
              <li key={index}>{link}
              </li>
              )}
          </ul>
        </div>

      </div>

    </footer>
  );

}

export default Footer;