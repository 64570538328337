import React from "react";

const BestQuote = () => {
  return (
    <div className="bq">
      <div className="best-quote orange">
        <div className="content">
          <h2 className="title">Best Quote</h2>
          <p className="description">Everyone who doubted me is askin' for forgiveness</p>
          <p className="description">If you ain't been a part of it, at least you got to witness,</p>
          <p className="description">bitches</p>
          <a href="https://www.youtube.com/watch?v=eDuRoPIOBjE&ab_channel=DrakeVEVO" className="link"> Drake - Forever </a>
        </div>

      </div>

    </div>
  );

}

export default BestQuote;