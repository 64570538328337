import React from "react";

export default function Card({image, title}) {
  return (
  <div className="card">
    <div className="card-image">
      <img src={image} alt="super" />
    </div>

    <div className="card-content">
      <div className="card-heading">
        <span className="card-series">{title}</span>
      </div>

    </div>

  </div>
  );
}