import React, {useState} from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { ImSun } from "react-icons/im";
import { BsFillMoonFill } from "react-icons/bs";
import dark_logo from "../assets/logo_name_dark_theme.png";
import light_logo from "../assets/logo_name_light_theme.png";

const Navbar = ({changeTheme, currentTheme}) => {
  const [navState, setNavState] = useState(false);
  return (
    <nav>
      <div className="brand-container">
        <div className="brand">
          <img src={currentTheme === "dark" ? dark_logo : light_logo} alt="logo" />
        </div>

        <div className="toggle-container">
          <div className="toggle">
            {
              navState ? 
              <MdClose onClick={() => setNavState(false)}/> 
              : 
              <GiHamburgerMenu onClick={() => setNavState(true)}/>
            }
          </div>
          <div className="mode" onClick={changeTheme}>
            {currentTheme === "dark" ? <ImSun className="light"/> : <BsFillMoonFill className="dark"/>}
          </div>
        </div>
      </div>

      <div className={`links-container ${navState ? "nav-visible" : ""}`}>
        <ul className="links">
          <li>
            <a href="/">Home</a>
          </li>

          <li>
            <a href="/about">About</a>
          </li>

          <li>
            <a href="/projects">Projects</a>
          </li>

          <li>
            <a href="/posts">Posts</a>
          </li>

          <li onClick={changeTheme}>
            {currentTheme === "dark" ? <ImSun className="light"/> : <BsFillMoonFill className="dark"/>}
          </li>
        </ul>
      </div>
    </nav>
  );

}

export default Navbar;