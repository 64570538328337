import React from "react";
import eve_image from "../../assets/projects/EVE/prototype.png";
import eve_full_body from "../../assets/projects/EVE/eve_full_body.jpeg";
import eve_head_focus from "../../assets/projects/EVE/eve_head_focus.jpeg";
import eve_upper_focus from "../../assets/projects/EVE/eve_upper_focus.jpeg";

const EVE = () => {

  return (
    <div className="eve">
      <div className="content">
        <h2 className="title">EVE</h2>
          <h2 className="subtitle">The next step for healthcare</h2>
          <img src={eve_head_focus} alt="eve_head_focus" />
          <p className="description">A healthcare robot prototype that enables weight, blood pressure
                                    / pills monitoring, exams tracking and skin cancer prediction. </p>
          
          <p className="long_description">
            Healthcare has become one of the most important problems of our society, and one way to 
            resolve it is by making things cheap. 
            In order to do that, we need to start implementing what has been called by me 
            <i> Home Testing</i> and <i>HealthDataCare</i>.
            The concept is simple: instead of making people pay (when they can) and wait in a 
            long line for exams, we make tests at home. <u>Cheap and effective</u> tests. 
            Current there is a few applications that HealthCare already can take benefits from. 
            They are called <b>Simple Daily Data:</b>
            - Those are the data that can already be taken from home. For example: Weight Measures, 
            Blood Pressure, Heart Rate, etc. </p>

          <p className="long_description"> 
            But we can go further and enable a new class of exams: 
            <b> Cancer Exams</b>. This is a sensitive topic, but it has a lot of potential.</p>
          
          <p className="long_description"> 
            Imagine if you can make a quick and simple test at home and 
            know in a couple minutes the likelihood that you have cancer.
            This is already possible with at least two types: 
            <i>Skin Cancer and Breast Cancer</i>.</p>

          <h2 className="subsubtitle">Skin Cancer</h2>
          <p className="long_description">
            The way to do it is pretty simple, and it is implemented with 
            this app. You just take a picture, fill some informations about the image 
            (your age, sex, location of the photo taken) and we have about 90% of precision in less 
            than a minute.
            There are ways to improve this results, if you use a dermatology photo 
            (one way implemented is having a microscopic.  </p>


          <h2 className="subsubtitle">Breast Cancer</h2>
          <p className="long_description"> 
            This is a little more tricky. The software is done, and it works similarly to the Skin Cancer.
             You simply present the image to the model and it does the inference. But the best way of 
             taking "picture" is with a ultrassonic transducer, which are (currently) too 
             <a href="https://www.philips.com.br/healthcare/sites/lumify"> expensive. </a>
            What we need to do is to redesign the ultrassonic transducer, making it more easy and 
            reliable for common people to use, and be able to make the test at home.</p>

          <h2 className="subsubtitle">To Note</h2>
          <p className="long_description">
            It is important to note that <b>this does not mean that you are not going to need a doctor</b>.
             This is just a <i>pre-test</i> that will need to be confirmed by a medical professional. 
             The point here is not to replace Doctors, but instead <i>help</i> them providing <u>more 
              data</u>, and making easier for common people to have diagnostics faster and cheaper.</p>

          <p className="long_description">
            While in that note, talking about sharing data to doctors, this is the other pillar that 
            this app is based on. Its time that the pacient has control over their entire Medical History, 
            since the day he/she were born. No more retaking tests because they were made by another
            Private Hospital (obligating people to pay for another exam). If you have the data, 
            the doctor can make the diagnose better and more precisely 
            (imagine two scenarios: pacient A comes to doctor not feeling good for a few days, 
            and pacient B who is suffering the same syntoms, but has their Medical History available 
            and Daily/Weekly data of their Health Status. The doctor can then study those informations
            and make a more conscious decision with the last pacient). </p>

          <h2 className="subtitle">Design Concept</h2>
          <img src={eve_image} alt="logo" />
          <p className="description">The idea was to create a prototype that was able to track 
                                      the health of users, since simple weights / blood pressure 
                                      until skin cancer prediction</p>
          

          <h2 className="subtitle">Final Prototype</h2>
          <img src={eve_full_body} alt="eve_full_body" />
          <img src={eve_upper_focus} alt="eve_upper_focus" />
          
      </div>

      <div className="source-container">
        <p className="source">
          You can check the source code for the EVE project in 
            <a href="https://github.com/dedsylva/EVE"> here </a>
        </p>
      </div>
    </div>
  )  
}

export default EVE;