import React from "react";

const Singularity = () => {

  return (
    <div className="singularity">
      <div className="content">
        <h2 className="title">The Singularity - 22/01/2022</h2>
          <h2 className="subtitle">The singularity is coming</h2>

          <h2 className="subsubtitle">What is the singularity ?</h2>
          <p className="long_description">
            The idea is simple: <a href="https://www.youtube.com/watch?v=7Pq-S557XQU&ab_channel=CGPGrey">Humans 
            need not apply.</a> So eventually we will become obsolete and the machines will out beat us in 
            pretty much everything. So what does that mean? Well, there will be no more jobs for people, 
            which will cause big <b>chaos</b> in our society. This is because the way we managed to decide 
            who gets what (product/goods) in our world is based on money (not quite, but more on that later). 
            And the way we (usually) get money? By working. So, if there are no more jobs, there won't be money, 
            therefore our system is broken. </p>
          
          <p className="long_description"> 
            Let me clarify: when I say that there won't be jobs, I mean that it won't be 
            <b> practical</b> anymore for humans to do work. We will simply become a lot inefficient 
            and expensive, the way horses became useless when cars arrived. And it's not about a question 
            of "Oh, my job is really unique, I am really good at this one particular thing". 
            Machines are great at optimizing, so you will become obsolete. From truck drivers to artists, 
            from engineers to doctors, the time will come for us all.</p>


          <p className="long_description"> 
            So, why exactly this will cause chaos in our society? Going back to "money", the actual 
            paper you use on a daily basis (or credit card, or crypto) is worthless, if there isn't 
            value associated with it. Money is simply <b>information of potential ownership. </b> 
            And why do we need this? If the machines are going to be great at everything, services 
            and products will become a lot easier to produce, they will be faster and more accurate, 
            which will make most things cheap. And <b><a href="https://cheapeth.org/">cheap is great! </a></b> 
            Well, for most things this is actually correct. So you won't have to work anymore, you are going 
            to have a robot doctor that fulfills most of your medical needs, you are going to have a 
            self-driving car, and many more great things. But, and here's the catch, there are resources that 
            are scarce/limited. And that's where money shines. How do you decide who should get resource A, 
            if it isn't available for everyone? Just pick the one who pays you more. What that (should) 
            implies is that this person values more this resource than the other person. And that is 
            precisely how most of our society works. But if people can't work and make money, how are they 
            going to show that they value A more? </p>


          <p className="long_description"> 
            That is one of the most important questions we as a society will have to answer by the end of 
            this century. Currently, the only "theory" that has been popping up is <b>UBI </b> 
            (Universal Basic Income). I personally think that this is a very bad idea because it doesn't 
            solve the problem at all, it's just one of those reactive projects the government 
            always tries. It doesn't tackle the root of the problem. If everyone will gain 2000 *** 
            (insert your coin here) then the companies that owe the robots/AI simply dominate more than 
            any other country in the world, and they (and the rich) will be the only ones that will have
             access to unique things. We need to consider this as an opportunity to review our value 
             system and come up with a better solution. Which solution that will be? I don't know, but 
             this is one of the things that keeps me up at night. People usually focus on what will 
             happen to humans in a philosophical way, but forget how our economy will simply collapse. 
             And this time printing money won't save us. It's time for social majors to start thinking 
             about this, and stop with the Left x Right debate. We need to start 
             <b> thinking differently</b> than we had for the past 2000 years. That's hard. 
             And will become harder as we approach the singularity. </p>

       <br></br> 
      </div>
    </div>
    )

}

export default Singularity