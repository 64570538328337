import React from "react";

const Future = () => {


  return (
    <div className="future">
      <div className="content">
        <h2 className="title">The Future - 17/07/2020</h2>

          <p className="long_description">
            Can you imagine a world where you don't have to work? Waking up whenever you want to, 
            without the pressure of 'making money'? Doing the things you want to do, the way you want 
            to do them? Not being stuck in an environment that only makes you fake? This is possible. 
            This is the future. </p>
    
          <p className="long_description">
            I know that this message may seem to be something taken from a movie. It is not, 
            I assure you. With the development of AI and the birth of real robots (yeah, real, not 
            what we have today), that's the world we're going to live in. No more unnecessary physical 
            labor for humans, no more driving, no more taking a crowded subway at 6 am to work for an 
            office that you don't give a shit to. </p>

          <p className="long_description">
            This is freedom. Freedom to do the things you always wanted to do. Go on a trip, meet other people, 
            stay in your house and just play videogames the whole day, you name it.
          </p>

          <p className="long_description">
            Some people say that with the robot revolution people won't have jobs anymore. 
            But they don't see how this is the best thing that can happen. There will be no more need to 
            'work for money'. As a matter of fact, there will be no need for money at all! 
            (But I'm sure that the politicians won't say that, because how are they supposed to control you then?) 
            I'm not saying that it will be easy. No revolution is done without the loss of innocent blood, 
            unfortunately. The worst part is that the ones who will suffer are the ones who will get the most 
            of the consequences of that phase. Let me explain. </p>


          <p className="long_description">
            It will start slowly. Actually, let me correct myself, it has already started. 
            Right now there are millions of people that are working to build the next generation of 
            robots. And then, there will come a day where an online presentation will happen. 
            I don't know when, I don't know what company, but it will change our lives the way the 
            Internet did, showed the way iPhone was. And then, the stocks of that particular company 
            will rise and things are going to be bad for a while. Like any other disruptive technology, 
            many people will be fired from their jobs and it will cause a lot of protests and riots. 
            A few governments are going to try to create laws and slow the process, guarantee the work 
            for as many people as possible. It will probably happen more with undeveloped countries like 
            it always do. But the innovations are going to continue rapidly and more people will lose 
            their jobs. It will seem like 'the end of the world', but not really. It's the end of our 
            slavery to others. Then there will come a time that people will realize there's no way to 
            come back, and the only option is to let it go. Politicians won't say it, but when they 
            start to try to change the conversation, that's when they gave up. By then the robots will 
            be among us and it will seem so natural like smartphones are today. There will of course 
            have a lot of social projects for 'basic and international human needs' that is basically 
            a lot of fake money to people who continue to believe in the current system. But it will 
            be too late. People will stop going to work (the few jobs that will still reluctantly persist), 
            people will stop going to the grocery, people won't vote anymore, the economy will collapse for 
            good and that will be the end of the power of politicians. </p>


          <p className="long_description">
            Unfortunately, you probably won't be alive for that last part. We today are in a unique 
            time where things are about to change for good, and we still can control how things are 
            going to happen. It sucks, right? Especially because after that phase people will have 
            robots doing almost everything, from tedious (cleaning your house, making your coffee, 
            looking for your phone, filling up paperwork) to essential things (diagnosing people, 
            driving, building houses), so there will be plenty of time to build better and smarter 
            stuff. If all of the things I've said has caused fear or sadness, you haven't figured out 
            how amazing the life of people will be in the future. You can actually do whatever you want 
            to do, without being forced to be a part of the scam we are today! Think about it. How many 
            times did you have to give up what you wanted to do and go to school, to work, to a social 
            event? And for what? School today is pathetic. They try to teach kids to memorize a lot of 
            useless things that computers can do very easily and very faster. All the way through 
            college, where you're supposed to 'find yourself' and 'get a job', building 'your future 
            and career'. That's the most stupid fake truth people tell you. There is no 
            'finding yourself', 'building a carreer'. Why? Because you have to work where other 
            people will accept you, not where you want to work (IF you want to work at all). 
            Imagine that weight on your shoulders just gone. That's how life is going to be in the 
            future. </p>


          <p className="long_description">
            Ok, hopefully, I've convinced you in that last paragraph. If I didn't, you will just have 
            to deal with it, because it's gonna happen. For those that do believe in me, you may ask 
            'Ok, how long from now?' That's tricky. This is where it can all change. The ones who make 
            the first leap can dictate how the pace is going to be. And there's a high probability that 
            big companies will get there first. The beauty is that, because it's such a different thing, 
            once it's out there people can create their own versions quickly. The sooner we understand 
            how things are going to change, and especially <b>how</b> things can change 
            <b> for the better</b>, the faster we can start to walk in that direction and make the 
            transition less painful. </p>


          <p className="long_description">
            So, to finish, I'm going to leave you with a different perspective of our present time. 
            It may seem like our lives today are not good, because we sometimes have to go to work in
             places that we don't want to and participate in a lot of scams, but today is the time when 
             the human mind may be at its peak. It's highly probable that we won't be able to do what the 
             smarter AI will, and the result is that fewer people are going to learn different things. 
             Unless we find a way to merge and learn things quickly 
             (those brain chips don't seem so bad now, right?), we are going to be left behind. 
             So <b>right now it's when we have the luxury to beat the machine and decide how to build them.</b> 
             So enjoy it while it lasts, because it won't last for long. Soon they will outsmart us, but that's 
             not necessarily a bad thing. </p>

       <br></br> 
      </div>
    </div>
    )

}

export default Future;