import React from "react";
import logo from "../assets/posts/stable_diffusion/logo.png"
import { useNavigate } from "react-router-dom";

const Home = () => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = "/projects"; 
    navigate(path);
  }

  return (
    <div className="home">
      <div className="container">
        <div className="content">
          <p className="sub-title">What's New </p>
          <h1 className="title">EVE</h1>
          <p className="description">A healthcare robot prototype that enables weight, blood pressure
                                    / pills monitoring, exams tracking and skin cancer prediction. </p>

          <button onClick={routeChange}>Learn More</button>
        </div>

        <div className="image-container">
          <div className="image">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="ellipse-container">
          <div className="ellipse pink"></div>
          <div className="ellipse orange"></div>
        </div>
      </div>
    </div>
  );

}

export default Home;