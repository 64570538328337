import React from "react";
import EVE from "./projects/EVE";
import CXQ from "./projects/CXQ";

const Projects = () => {

  return (
    <div className="projects">
      <div className="content">
        <h2 className="title">Projects</h2>

        <hr/>
        <EVE />
        <hr/>
        <CXQ />
      </div>
    </div>
  )  
}

export default Projects;