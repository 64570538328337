import React from "react";

const Quotes = () => {

  return (
    <div className="beautiful-quotes">
      <div className="content">
        <h2 className="title">Beautiful Quotes - 01/01/2016</h2>

          <p className="quote">
            - Anybody accidentally kills anybody in a fight they go to jail, it's called manslaughter. 
          </p>
          
          <p className="quote">
            - Life is umpredictable. Not everything is in our control. But, as long as we're with the right people, we can handle anything.
          </p>
          
          <p className="quote">
            - The greatest successes come from having the freedom to fail
          </p>
          
          <p className="quote">
            - Sometimes it is the people no one imagines anything of who do the things that no one can imagine.
          </p>
           
          <p className="quote">
            - Guess beggars can't be choosey.
          </p>
           
          <p className="quote">
            - Fear is an act of imagination.
          </p>
           
          <p className="quote">
            - Who wears shorts to a meeting?
          </p>
            
          <p className="quote">
            - I just wanna say thank you so much not only to god, but to jesus.
          </p>
            
          <p className="quote">
            - People are cowards at the moment because if they're not cowards they're unemployed.
          </p>
            
          <p className="quote">
            - What is grief, if not love persevering?
          </p>
             
          <p className="quote">
            - Every subject is much easier than the people who wish to make money teaching it would want you to know.
          </p>
          
          <p className="quote">
            - Your opinion doesn't matter in a scientific answerable question.
          </p>
           
          <p className="quote">
            - If you only do what you can do, you will never be more than you are now
          </p>
           
          <p className="quote">
            - Your opinion doesn't matter in a scientific answerable question.
          </p>
           
          <p className="quote">
            - Everybody wanted to know what i would do if i didn't win. I guess we'll never know.
          </p>
            
          <p className="quote">
            - Day by day he gazed upon her, Day by day he sighed with passion.
          </p>
             
          <p className="quote">
            - Well, it's official. Friday, December 13th, 1963. Four guys shot their own legs off.
          </p>
              
          <p className="quote">
            - Keep rolling the dices.
          </p>
               
          <p className="quote">
            - It's fucking fake! I don't know who scammed you into believing this numbers with any kind of value at all. The money is made up, is backed by nothing. You are playing for points in some guy's game, and the guy who made the game rigged the whole thing.
          </p>
               
          <p className="quote">
            - If one is to understand the great mystery, one must study all its aspects, not just the dogmatic narrow view of the Jedi.
          </p>
               
          <p className="quote">
            - Everyone who doubted me is askin' for forgiveness, If you ain't been a part of it, at least you got to witness, bitches.
          </p>
       
       <br></br> 
      </div>
    </div>
    )

}

export default Quotes;