import React from "react";
import cats1 from "../../assets/posts/stable_diffusion/cats_astronauts_on_mars.png";
import cats2 from "../../assets/posts/stable_diffusion/cats_astronauts_on_mars2.png";
import dog1 from "../../assets/posts/stable_diffusion/dog_on_mars.png";
import dog2 from "../../assets/posts/stable_diffusion/dog_on_mars_2.png";
import dog3 from "../../assets/posts/stable_diffusion/dog_astrounats_on_mars.png";
import half from "../../assets/posts/stable_diffusion/half_dog_half_robot.png";
import hands from "../../assets/posts/stable_diffusion/holding_hands.png";
import logo from "../../assets/posts/stable_diffusion/logo.png";
import working_out from "../../assets/posts/stable_diffusion/robot_working_out.png";
import dinner from "../../assets/posts/stable_diffusion/romantic_dinner.png";

const StableDiffusion = () => {


  return (
    <div className="singularity">
      <div className="content">
        <h2 className="title">Stable Diffusion - 25/09/2022</h2>
          <h2 className="subtitle">The future of ART</h2>

          <p className="description">
            <a href="https://github.com/CompVis/stable-diffusion">Stable Diffusion </a> 
            is the new fresh AI Neural Network that has come out last month. It can produce images, 
            given a text input, and is the <i>open source</i> version of
            <a href="https://openai.com/dall-e-2/"> Dalle</a>. In the post <b>The Singularity</b> I
            said that <i> From truck drivers to artists, from engineers to doctors, the time will 
              come for us all.</i> With selfing driver cars becoming 
            <a href="https://www.tesla.com/autopilot"> better</a> and 
            <a href="https://comma.ai/"> better</a>, now is the time for artists to feel this new wave
            of time and technology. </p>

          <p className="description">
            I played around with the model, and it seems to be really good! It can understand pretty well
            the context that you meant, and the images generated are of really high quality. Below there
            is some examples of the results I got using 
            <a href="https://github.com/woctezuma/stable-diffusion-colab"> google colab.</a>
          </p>

          <div className="images">

          <h2 className="subsubtitle">Cats Astronauts on Mars</h2>
            <div className="side">
              <img src={cats1} alt="cats1" />
              <img src={cats2} alt="cats2" />
            </div>

          <h2 className="subsubtitle">Dog Astronauts on Mars</h2>

            <div className="side">
              <img src={dog1} alt="dog1" />
              <img src={dog2} alt="dog2" />
            </div>

          <h2 className="subsubtitle">Dog Astronaut Arriving on Mars</h2>
          <img src={dog3} alt="dog3" />

          <h2 className="subsubtitle">Half Dog Half Robot</h2>
          <img src={half} alt="half" />

          <h2 className="subsubtitle">Robots Having a Romantic Dinner</h2>
            <div className="side">
              <img src={hands} alt="hands" />
              <img src={dinner} alt="dinner" />
            </div>

          <h2 className="subsubtitle">Robot Working Out</h2>
          <img src={working_out} alt="working_out" />

          <h2 className="subsubtitle">Health Robot Logo (Logo used in EVE Project)</h2>
          <img src={logo} alt="logo" />


          </div>


          <br></br> 
      </div>
    </div>
    )  

}

export default StableDiffusion;
