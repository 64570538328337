import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="content">
        <h2 className="title">About</h2>
        <p className="description"> This is the personal website of Dedsylva.</p>
        <p className="description"> In it you can find posts about life and projects that I'm currently working on.</p>
      </div>
    </div>
  )

}

export default About;