import React from "react";
import Home from "./Home";
import LatestPost from "./latestPost";
import BestQuote from "./BestQuote";

const MainPage = () => {

  return (
    <>
      <Home />
      <LatestPost /> 
      <BestQuote />
    </>
  )

}

export default MainPage;