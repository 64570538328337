import React from "react";
import Card from "./Card";
import super1 from "../assets/posts/stable_diffusion/cats_astronauts_on_mars2.png";
import super2 from "../assets/posts/stable_diffusion/holding_hands.png";

const LatestPost = () => {
  const data = [
    {
      image: super1,
      title: "Astronaut on Mars",
    },
    {
      image: super2,
      title: "Robots having a romantic dinner",
    },
  ];

  return (
    <div className="latest-post">
      <div className="title-container">
        <h1 className="title">Latest Post</h1>
        <p className="description"> <a href="/projects">Stable Diffusion:</a> the future of art.
        </p>
      </div>

      <div className="cards">
        {
          data.map(({ image, title}, index) =>
            <Card
              image={image}
              title={title}
              key={index}
            />
          )
        }
      </div>

    </div>
  )
}

export default LatestPost;