import React from "react";
import Singularity from "./posts/Singularity";
import Future from "./posts/Future";
import Quotes from "./posts/Quotes";
import StableDiffusion from "./posts/StableDiffusion";

const Posts = () => {

  return (
    <div className="posts">
      <div className="content">
        <h2 className="title">Posts</h2>

        <hr/>
        <StableDiffusion />
        <hr/>
        <Singularity />
        <hr/>
        <Future />
        <hr/>
        <Quotes />
      </div>
    </div>
  )    

}

export default Posts;